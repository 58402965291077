@import url('https://fonts.googleapis.com/css2?family=Alkalami&family=Noto+Sans+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');


html {
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: scrollbar;
    scroll-behavior: smooth;

  }
  
  *{
      font-family:'Alkalami', serif;
     
  }
 
  body {
    background: #fff;
    font-family: 'Alkalami', serif;
    color: #666;
    -webkit-font-smoothing: antialiased;
  }

  .align-center {
    text-align: center;
  }
  
  .align-left {
    text-align: left !important;
  }
 
.navbar-custom{
    background-color: #4C0033;
    font-family: 'Alkalami', serif;
    font-size: 20px;
    border: 0;
    border-radius: 0;
    z-index: 1000;
  transition: background, padding 0.4s ease-in-out 0s;
    color: #DFB722;

}
.navbar-manage{
  display: flex;
  justify-content: end;
  align-items: flex-end;
}
.navbar-custom:hover{
color: white;
}
.navbar-custom .dropdown-menu {
    box-shadow: none;
  }

  .navbar-custom .dropdown-menu {
    background: rgba(26, 26, 26, 0.9);
    border-radius: 0;
    border: 0;
    padding: 0;
    box-shadow: none;
  }
  .navbar-custom .dropdown-menu .dropdown-menu {
    border-left: 1px solid rgba(73, 71, 71, 0.15);
    left: 100%;
    right: auto;
    top: 0;
    margin-top: 0;
  }

  
  .navbar-social-menu{
    display: flex;
    justify-content: center;
  }
   .navbar-toggler-icon   {
    border-color: white;
    color: #fff;
  }
  /* Vertical margin, padding */

  .mx-40{
    margin-left: 40px;
    margin-right: 40px;
  }
.p-0 {
    padding: 0 !important;
  }
  
  /* Selection */
  ::-moz-selection {
    background: #000;
    color: #fff;
  }
  
  ::-webkit-selection {
    background: #000;
    color: #fff;
  }
  
  ::selection {
    background: #000;
    color: #fff;
  }

.sm-icons {
    flex-direction:row;
   }

   .sm-icons .nav-social-link{
    /* padding-right:1em; */
    padding-left: 1em;
   }

   /* image size */
  .carousel-img{
    height:600px;
    width: 800px;
  }
  .check-img{
    height: 40px;
    width: 40px;
}
  .bg-img-size{
    height: 500px;
    width: 1340px;
  }
  .bg-img-size1{
    height: 880px;
    width: 1342px;
  }
  .bg-img-size2{
    height: 900px;
    width: 1348px;
  }
  .bg-img-size-samosa{
    height: 650px;
    width: 1330px;
}
.home-page-img{
    height:600px;
    width:700px;
}
.home-page-img1{
    height:600px;
    width:700px;
}
.product-img{
    height: 400px;
    width: 400px;
}
.bg-img-product{
    height: 650px;
    width: 1330px;
  }
  .bg-img-size-rawa{
    height: 650px;
    width: 1340px;
}
.bg-img-size-suji{
    height: 650px;
    width: 1330px;
}
 
   /* whatsup scroll */
.float{
         position:fixed;
        width:60px;
        height:60px;
        bottom:40px;
        right:40px;
        background-color:#25d366;
        color:#FFF;
        border-radius:50px;
        text-align:center;
      font-size:30px;
     box-shadow: 2px 2px 3px #999;
      z-index:100;
    
}
.my-float{
	margin-top:13.5px;
    text-align: center;
    right:30px;
    margin-left:-28px;
    /* overflow: scroll; */
    }

/* home page start */
.container-home{
    position: relative;
    text-align: center;
    color: white;
}
.centered {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
.home-text{
    font-family: "Kreon, Georgia, Times New Roman, serif";
}

.block{
    position: relative;
    margin: 10px auto 0;
}
.block::before{
    z-index: -1;
    top:-2px ;
    left:-2px ;
    right:-2px ;
    bottom:-2px ;
    background: transparent;

}
.block::after{
    top:-2px ;
    left:-2px ;
    right:-2px ;
    bottom:-2px ;
    z-index: -1;
    filter: blur(40px);
    
}
.block::before,
.block::after
{
    content: '';
    position: absolute;
    top:-2px ;
    left:-2px ;
    right:-2px ;
    bottom:-2px ;
    background: linear-gradient(300deg, #e6fb04,#ff6600, #00ff66, #00ffff, #eea1ee, #ff0099,#6e0dd0, #d34a28, #099fff);
    background-size: 400%;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -1;    
    animation: animate 10s linear infinite;
    background-repeat: repeat;
    filter: blur(10px);

}

@keyframes animate{
    0%{
        background-position: 0, 0;
    }
    50%{
        background-position: 400%, 0;
    }
    100%{
        background-position: 0, 0;
    }
}


.home-bg{
    margin-top: 0px;
    background: url('../pages/mrunali-images/maidaBg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

    .home-maida{
        margin-left:100px;
        margin-right: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bg-img{
        content: '';
        position: absolute;
        top:-2px ;
        left:-2px ;
        right:-2px ;
        bottom:-2px ;
        background: #fff;
        z-index: -1;
    }

    .animate-design {
        overflow: hidden;
        animation: animate 4s linear forwards;
    }
 
    
    .animate-design h1 {
        color: #4C0033;
    }
    .animate-design h2 {
        color: #4C0033;
    }
    .animate-design h6 {
        color: #4C0033;
    }
    .animate-design p {
        color: #4C0033;
    }
    @keyframes animate {
        0% {
            width: 0px;
            height: 0px;
        }
        30% {
            width: 50px;
            height: 0px;
        }
        60% {
            width: 50px;
            height: 80px;
        }
        from {background-color: black;}
  to {background-color: transparent;}
    }
  /* home page end */

.about{
    margin-top: 50px;
   color: #4C0033;
    font-family: 'Alkalami', serif;
    font-size: 50px;

}
.about-bg{
    background: url('../pages/mrunali-images/sujibg.jpg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);}

.about-detail{
    margin-top: 50px;
    color: black;
     font-family: 'Alkalami', serif;
     font-size: 18px;
}
.about-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    cursor: pointer;
}

/* footer page start */
.footer{
    /* background-color: #4C0033; */
    background-color: #A10035;
}
.footer-title{
    color: white;
    font-family: 'Alkalami', serif;
    font-size: 35px;

}
.footer-heading{
    margin-top: 25px;
    color: white;
    font-family: 'Lato', sans-serif;
    font-size: 14px;

}
/* footer page end */

/* contact page start */
.contact{
    background-color: #4C0033;
    color: white;
    font-family: 'Alkalami', serif;
    font-size: 20px;
}
.contact-bg{
    background: url('../pages/mrunali-images/contactbg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
/* contact page end */


.carouselAll{
    box-sizing: border-box;
    margin-bottom: 30px ;
     height: 90%;
    width: 90%;
    user-select: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../pages/mrunali-images/bg.jpg');
background-size: cover;
    background-color: transparent;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.carousel-container{
    width: 90%;
    padding: 20px;
    height: auto;
    margin: 80px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;


}


.product-dropdown{
    background-color: white;
    color:  #A10035;
    font-family: 'Alkalami', serif;
    font-size: 20px;
    border-radius: 12px;
     text-align: center;
}

/* product-page start */
.product{
    /* background: url('../pages/mrunali-images/productbg1'); */
    background: url('../pages/mrunali-images/maidaBg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);}

.product-heading{
    color: #4C0033;
    font-family: 'Alkalami', serif;

}
/* product-page end */

/* our customers-page start */
.customer-heading{
    color: #4C0033;
    font-family: 'Alkalami', serif;
    font-size: 20px;


}
/* our customers-page end */

/* atta page */
.atta-img{
    background:url('../pages/mrunali-images/maidaBg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
  
.atta-text{
    margin-top: 190px;
    margin-left: 20%;
    margin-right: 40%;
}

.box-atta{
    margin-top:50% ;
    margin-left:30% ;
    padding: 5px;
    width: 650px;
    display: flex;
    justify-content: center;
  
}

/* maida page  */
.maida-bg{
    margin-top: 0px;
    background: url('../pages/mrunali-images/bgNew.jpg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);}

    .box-maida{
        margin-top:50% ;
        margin-left:30% ;
        padding: 5px;
        width: 350px;
        display: flex;
        justify-content: center;
      
    }

    /* rawa page start */
    .box-rawa{
        margin-top:2rem ;
        margin-left:10% ;
        padding: 5px;
        width: 300px;
        display: flex;
        justify-content: center;
        
    }
    .atta-slider{
        height: 400px;
        width: 400px;
      }

    .rawa-bg{
        margin-top: 0px;
        background: url('../pages/mrunali-images/bgNew.jpg');
        background-repeat: no-repeat, repeat;
        background-size: cover;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);}
    
    /* rawa page end */

    /* suji page start */
    .box-suji{
        margin-top:2rem ;
        margin-left:10% ;
        padding: 5px;
        width: 300px;
        display: flex;
        justify-content: center;
        
    }

    .suji-bg{
        margin-top: 0px;
        background: url('../pages/mrunali-images/bgNew.jpg');
        background-repeat: no-repeat, repeat;
        background-size: cover;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);}

        .chakkiLogo{
            height: 40px;
            width: 40px;
        }
    
        .our-customer-img{
            height: 400px;
            width: 1350px;
          }
        .home-para-text{
            margin-left: 25px;
                    }
    /* suji page end */

    @media screen and (max-width:768px){
        
        .chakkiLogo{
            height: 30px;
            width: 30px;
        }
        .our-customer-img{
            height: 400px;
            width: 400px;
          }

        .atta-slider{
            height: 200px;
            width: 200px;
          }
        .home-para-text{
          margin-left: 10px;
                    }
        .sm-icons {
            flex-direction:row;
           }
        
           .sm-icons .nav-social-link{
            padding-left: 1em;
           }
           .carousel-img{
            height: 330px;
            width:550px
           }
           /* whatsup scroll */
        .float{
             position:fixed;
                width:40px;
                height:30px;
                bottom:20px;
                right:0px;
                background-color:#25d366;
                color:#FFF;
                border-radius:50px;
                text-align:center;
              font-size:20px;
             box-shadow: 2px 2px 3px #999;
              z-index:100;
            
        }

        .navbar-manage{
          display: flex;
          justify-content: start;
          align-items: flex-start;
        }
        .my-float{
            margin-top:13.5px;
            text-align: center;
            right:30px;
            margin-left:-28px;
            /* overflow: scroll; */
            }

            /* home page start */

.container-home{
    position: relative;
    text-align: center;
    color: white;
    
}
.centered {
    position: absolute;
    top: 32%;
    left: 63%;
    transform: translate(-50%, -50%);
    height: 300px;
    width: 400px;
  }
.home-text{
    font-family: "Kreon, Georgia, Times New Roman, serif";
}
 .bg-img-size{
    height: 400px;
    width: 386px;
}
.check-img{
    height: 30px;
    width: 30px;
}
.bg-img-size1{
    height: 400px;
    width: 390px;
  }
  .bg-img-size2{
    height: 400px;
    width: 390px;
  }
.home-page-img{
    height: 400px;
    width:400px;
}
.home-page-img1{
    
    height: 300px;
    width:340px;
}
.home-page-img2{
    margin-left: 150px;
    height: 300px;
    width:340px;
}
.product-img{
    height: 400px;
    width: 300px;
}

.product-manage {
  position: absolute;
  left: 50%;
  top: 50%;
}

.block{
    position: relative;
    margin: 10px auto 0;
}
.block::before{
    z-index: -1;
    top:-2px ;
    left:-2px ;
    right:-2px ;
    bottom:-2px ;
    background: transparent;

}
.block::after{
    top:-2px ;
    left:-2px ;
    right:-2px ;
    bottom:-2px ;
    z-index: -1;
    filter: blur(40px);
    
}
.block::before,
.block::after
{
    content: '';
    position: absolute;
    top:-2px ;
    left:-2px ;
    right:-2px ;
    bottom:-2px ;
    background: linear-gradient(300deg, #e6fb04,#ff6600, #00ff66, #00ffff, #eea1ee, #ff0099,#6e0dd0, #d34a28, #099fff);
    background-size: 400%;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -1;    
    animation: animate 10s linear infinite;
    background-repeat: repeat;
    filter: blur(10px);

}

/* @keyframes animate{
    0%{
        background-position: 0, 0;
    }
    50%{
        background-position: 200%, 0;
    }
    100%{
        background-position: 0, 0;
    }
} */

    .home-maida{
        margin-left:-23px;
        margin-right: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bg-img{
        content: '';
        position: absolute;
        top:-2px ;
        left:-2px ;
        right:-2px ;
        bottom:-2px ;
        background: #fff;
        z-index: -1;
    }

    /* .animate-design {
        overflow: hidden;
        animation: animate 4s linear forwards;
    }
 
    
    .animate-design h1 {
        color: #4C0033;
    }
    .animate-design h2 {
        color: #4C0033;
    }
    .animate-design h6 {
        color: #4C0033;
    }
    .animate-design p {
        color: #4C0033;
    }
    @keyframes animate {
        0% {
            width: 0px;
            height: 0px;
        }
        30% {
            width: 50px;
            height: 0px;
        }
        60% {
            width: 50px;
            height: 80px;
        }
        from {background-color: black;}
  to {background-color: transparent;}
    } */
  /* home page end */

.about{
    margin-top: 50px;
   color: #4C0033;
    font-family: 'Alkalami', serif;
    font-size: 50px;

}
.about-bg{
    background: url('../pages/mrunali-images/sujibg.jpg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);}

.about-detail{
    margin-top: 50px;
    color: black;
     font-family: 'Alkalami', serif;
     font-size: 18px;
}
.about-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    cursor: pointer;
}


/* contact page start */
.contact{
    background-color: #4C0033;
    color: white;
    font-family: 'Alkalami', serif;
    font-size: 20px;
}
.contact-bg{
    background: url('../pages/mrunali-images/contactbg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
/* contact page end */


.carouselAll{
    box-sizing: border-box;
    margin-bottom: 30px ;
     height: 300px;
    width: 320px;
     left:-31px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../pages/mrunali-images/bg.jpg');
background-size: cover;
    background-color: transparent;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.carousel-container{
    width: 80%;
    padding: 65px;
    height: auto;
    margin: 20px auto;
    display: flex;
    left: 50%;
    top: 50%;
    right: 50%;
    flex-direction: row;
    justify-content: center;


}


.product-dropdown{
    background-color: white;
    color:  #A10035;
    font-family: 'Alkalami', serif;
    font-size: 20px;
    border-radius: 12px;
     text-align: center;
}

/* product-page start */
.product{
  background: url('../pages/mrunali-images/maidaBg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);}

.product-heading{
    color: #4C0033;
    font-family: 'Alkalami', serif;

}
/* product-page end */

/* our customers-page start */
.customer-heading{
    color: #4C0033;
    font-family: 'Alkalami', serif;
    font-size: 20px;


}
/* our customers-page end */

/* atta page */

.atta-text{
    margin-top: 190px;
    margin-left: 20%;
    margin-right: 40%;
}

    }

    @media screen and (max-width:481px){
        .navbar-custom{
            background-color: #4C0033;
            width: auto;
            font-family: 'Alkalami', serif;
            font-size: 18px;
            border: 0;
            border-radius: 0;
            z-index: 1000;
          transition: background, padding 0.4s ease-in-out 0s;
            color: #DFB722;
        
        }
        .navbar-manage{
          display: flex;
          justify-content: end;
          align-items: flex-end;
        }
        .navbar-custom:hover{
        color: white;
        }
        .navbar-custom .dropdown-menu {
            box-shadow: none;
          }
        
          .navbar-custom .dropdown-menu {
            background: rgba(26, 26, 26, 0.9);
            border-radius: 0;
            border: 0;
            padding: 0;
            box-shadow: none;
          }
          .navbar-custom .dropdown-menu .dropdown-menu {
            border-left: 1px solid rgba(73, 71, 71, 0.15);
            left: 100%;
            right: auto;
            top: 0;
            margin-top: 0;
          }
        
          
          .navbar-social-menu{
            display: flex;
            justify-content: center;
          }
           .navbar-toggler-icon   {
            border-color: white;
            color: #fff;
          }
        
        .sm-icons {
            margin-top: 10px;
            flex-direction:row;
           }
        
           .sm-icons .nav-social-link{
            /* padding-right:1em; */
            padding-left: 1em;
           }
           .carousel-img{
            height: 300px;
            width:500px
           }
           /* whatsup scroll */
        .float{
             position:fixed;
                width:60px;
                height:60px;
                bottom:40px;
                right:80px;
                background-color:#25d366;
                color:#FFF;
                border-radius:50px;
                text-align:center;
              font-size:15px;
             box-shadow: 2px 2px 3px #999;
              z-index:100;
            
        }

        .navbar-manage{
          display: flex;
          justify-content: start;
          align-items: flex-start;
        }
        .my-float{
            margin-top:13.5px;
            text-align: center;
            right:30px;
            margin-left:-11px;
            /* overflow: scroll; */
            }

            /* home page start */

.container-home{
    position: relative;
    text-align: center;
    color: white;
    
}
.centered {
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 300px;
    width: 400px;
  }
.home-text{
    font-family: "Kreon, Georgia, Times New Roman, serif";
}
.home-bg{
    margin-top: 0px;
    background: url('../pages/mrunali-images/maidaBg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    width: 100%;
}

 .bg-img-size{
    height: 400px;
    width: 388px;
}
.check-img{
    height: 30px;
    width: 30px;
}
.bg-img-size-samosa{
    height: 400px;
    width: 361px;
}
.bg-img-size-rawa{
    height: 400px;
    width: 375px;
}
.bg-img-size-suji{
    height: 400px;
    width: 363px;
}
.bg-img-size1{
    height: 400px;
    width: 375px;
  }
.bg-img-product{
    height: 400px;
    width: 400px;
  }
  .bg-img-size2{
    height: 400px;
    width: 383px;
  }
  .our-customer-img{
    height: 400px;
    width: 372px;
  }
  .atta-slider{
    height: 250px;
    width: 250px;
  }
.home-page-img{
    height: 400px;
    width:377px;
}
.home-page-img1{
    height: 300px;
    width:300px;
}
.home-page-img2{
    margin-left: 150px;
    height: 300px;
    width:340px;
}
.product-img{
    height: 400px;
    width: 300px;
}

.product-manage{
  position: absolute;
  left: 50%;
  top: 50%;
}

.block{
    position: relative;
    margin: 10px auto 0;
}
.block::before{
    z-index: -1;
    top:-2px ;
    left:-2px ;
    right:-2px ;
    bottom:-2px ;
    background: transparent;

}
.block::after{
    top:-2px ;
    left:-2px ;
    right:-2px ;
    bottom:-2px ;
    z-index: -1;
    filter: blur(40px);
    
}
/* .block::before,
.block::after
{
    content: '';
    position: absolute;
    top:-2px ;
    left:-2px ;
    right:-2px ;
    bottom:-2px ;
    background: linear-gradient(300deg, #e6fb04,#ff6600, #00ff66, #00ffff, #eea1ee, #ff0099,#6e0dd0, #d34a28, #099fff);
    background-size: 400%;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -1;    
    animation: animate 10s linear infinite;
    background-repeat: repeat;
    filter: blur(10px);

} */

/* @keyframes animate{
    0%{
        background-position: 0, 0;
    }
    50%{
        background-position: 400%, 0;
    }
    100%{
        background-position: 0, 0;
    }
} */

    .home-maida{
        margin-left:-23px;
        margin-right: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bg-img{
        content: '';
        position: absolute;
        top:-2px ;
        left:-2px ;
        right:-2px ;
        bottom:-2px ;
        background: #fff;
        z-index: -1;
    }

    /* .animate-design {
        overflow: hidden;
        animation: animate 4s linear forwards;
    }
 
    
    .animate-design h1 {
        color: #4C0033;
    }
    .animate-design h2 {
        color: #4C0033;
    }
    .animate-design h6 {
        color: #4C0033;
    }
    .animate-design p {
        color: #4C0033;
    }
    @keyframes animate {
        0% {
            width: 0px;
            height: 0px;
        }
        30% {
            width: 50px;
            height: 0px;
        }
        60% {
            width: 50px;
            height: 80px;
        }
        from {background-color: black;}
  to {background-color: transparent;}
    } */
  /* home page end */

.about{
    margin-top: 50px;
   color: #4C0033;
    font-family: 'Alkalami', serif;
    font-size: 50px;

}
.about-bg{
    background: url('../pages/mrunali-images/sujibg.jpg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);}

.about-detail{
    margin-top: 56px;
    margin-left: -23px;
    color: black;
     font-family: 'Alkalami', serif;
     font-size: 19px;
}
.about-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    cursor: pointer;
}


/* contact page start */
.contact{
    background-color: #4C0033;
    color: white;
    font-family: 'Alkalami', serif;
    font-size: 15px;
}
.contact-bg{
    background: url('../pages/mrunali-images/contactbg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
/* contact page end */


.carouselAll{
    box-sizing: border-box;
    margin-bottom: 30px ;
     height: 300px;
    width: 320px;
     left:-31px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../pages/mrunali-images/bg.jpg');
background-size: cover;
    background-color: transparent;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.carousel-container{
    width: cover;
    padding: 50px;
    height: auto;
    margin: 20px auto;
    display: flex;
    left: 50%;
    top: 50%;
    right: 50%;
    flex-direction: row;
    justify-content: center;


}


.product-dropdown{
    background-color: white;
    color:  #A10035;
    font-family: 'Alkalami', serif;
    font-size: 15px;
    border-radius: 12px;
     text-align: center;
}

/* product-page start */
.product{
  background: url('../pages/mrunali-images/maidaBg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);}

.product-heading{
    color: #4C0033;
    font-family: 'Alkalami', serif;

}
/* product-page end */

/* our customers-page start */
.customer-heading{
    color: #4C0033;
    font-family: 'Alkalami', serif;
    font-size: 15px;


}
/* our customers-page end */

/* atta page */

.atta-text{
    margin-top: 190px;
    margin-left: 20%;
    margin-right: 40%;
}



        
    }

  

   
      
